<template>
    <div v-show="show" class="Dialog">
        <div class="Dialog-context">
            <div class="Dialog-header">
                <slot name="header"></slot>
            </div>
            <div class="Dialog-body">
                <slot></slot>
            </div>
            <div class="Dialog-footer">
                <slot name="footer"></slot>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        show: {
            type: Boolean,
        }
    }
}
</script>

<style scoped>
.Dialog {
    background: rgba(13, 14, 14, 0.5);
    @apply fixed z-999 w-full h-full top-0 left-0 flex items-center justify-center;
    @apply mobile:(flex-col justify-end);
}

.Dialog-context {
    @apply bg-white p-32px rounded-16px max-w-480px max-h-90vh flex flex-col;
    @apply mobile:(max-w-full rounded-b-none);
}
.Dialog-body {
    @apply flex-1 overflow-y-auto;
}
.Dialog-footer {
    @apply flex mt-24px pb-[env(safe-area-inset-bottom)];
}
</style>