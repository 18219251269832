<template>
    <Dialog :show="showDialog">
        <template #header>
            <h3 class="text-dark text-sm">{{ text.title }}</h3>
        </template>
        <div class="text-dark text-xs mt-12px" v-html="text.content"></div>
        <template #footer>
            <button class="btn flex-1" @click="clickConfirm">{{ text.btnText }}</button>
        </template>
    </Dialog>
</template>
<script>
import Dialog from './index.vue'
import { staticData } from '../../data.js'

const UK_DIALOG_KEY = 'ukDialogConfirm'
export default {
    components: {
        Dialog
    },
    data() {
        return {
            text: staticData.ukDialog,
            showDialog: false,
        }
    },
    mounted() {
        const ukDialogConfirm = sessionStorage.getItem(UK_DIALOG_KEY);
        this.showDialog = !ukDialogConfirm;
    },
    methods: {
        clickConfirm(e) {
            sessionStorage.setItem(UK_DIALOG_KEY, '1')
            this.showDialog = false;
        }
    }
}

</script>

<style scoped></style>